<template>
  <div id="main">
    <v-app id="app">
      <v-navigation-drawer v-model="drawer" app>
        <div class="d-flex flex-column justify-space-between align-center">
          <v-img
            contain
            :max-width="86"
            :max-height="56"
            src="../assets/logo.png"
          ></v-img>
        </div>
        <v-list expand nav>
          <!-- ENTIRE list is wrapped in a template -->
          <template v-for="(item, i) in menuItems">
            <!-- use v-if to determine if 2nd level nesting is needed -->
            <!-- if it's a menu item with no children -->
            <v-list-item
              v-if="!item.children && item.show"
              :key="`subheader-${i}`"
              color="secondary"
              :to="item.link"
            >
              <v-list-item-action>
                <v-badge v-if="item.badge" color="red">
                  <span slot="badge">{{ item.badge }}</span>

                  <v-icon>{{ item.icon }}</v-icon>
                </v-badge>
                <v-icon v-else>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- else if it has children -->
            <v-list-group
              v-else-if="item.show"
              :key="`subheader-${i}`"
              :group="item.link"
              color="secondary"
            >
              <!-- this template is for the title of top-level items with children -->
              <template #activator>
                <v-list-item-action>
                  <v-badge v-if="item.badge" color="red">
                    <span slot="badge">{{ item.badge }}</span>

                    <v-icon>{{ item.icon }}</v-icon>
                  </v-badge>
                  <v-icon v-else>
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item-content>
              </template>
              <!-- this template is for the children/sub-items (2nd level) -->
              <template v-for="(subItem, j) in item.children">
                <!-- another v-if to determine if there's a 3rd level -->
                <!-- if there is NOT a 3rd level -->
                <v-list-item
                  v-if="!subItem.children && item.show"
                  :key="`subheader-${j}`"
                  class="ml-5"
                  :to="subItem.link"
                >
                  <v-list-item-action>
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ subItem.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- if there is a 3rd level -->
                <v-list-group
                  v-else-if="item.show"
                  :key="`subheader-${j}`"
                  color="secondary"
                  :group="subItem.link"
                  sub-group
                >
                  <template #activator>
                    <v-list-item-action>
                      <v-icon>{{ subItem.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ subItem.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item-content>
                  </template>
                  <template v-for="(subSubItem, k) in subItem.children">
                    <v-list-item
                      :key="`subheader-${k}`"
                      color="secondary"
                      :value="true"
                      :to="subSubItem.link"
                    >
                      <v-list-item-action>
                        <v-icon>{{ subSubItem.icon }}</v-icon>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ subSubItem.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-group>
              </template>
            </v-list-group>
          </template>

          <!-- <v-list-item-group
            color="primary"
          >
            <v-list-item
              v-for="item in menuItems"
              :key="item.title"
              :to="item.link"
            >
              <v-list-item-action>
                <v-badge
                  v-if="item.badge"
                  color="red"
                >
                  <span slot="badge">{{ item.badge }}</span>

                  <v-icon>{{ item.icon }}</v-icon>
                </v-badge>
                <v-icon v-else>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group> -->
        </v-list>
      </v-navigation-drawer>

      <v-app-bar color="primary" app>
        <v-app-bar-nav-icon
          class="black--text"
          @click.stop="drawer = !drawer"
        />
        <v-toolbar-title class="black--text">
          {{ appName }}
        </v-toolbar-title>
        <v-spacer />
        <v-menu bottom rounded offset-y
          ><template v-slot:activator="{ attrs, on }">
            <v-btn icon x-large v-on="on" v-bind="attrs">
              <v-avatar color="grey lighten-2" class="mx-2" size="36">
                <v-img :src="userAvatar"></v-img>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in avatarMenuItems"
              :key="item.title"
              :to="item.link"
              link
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <span>
          {{ userFullName }}
        </span>
        <v-btn icon @click="logout">
          <v-icon class="black--text">mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>

      <v-main>
        <main>
          <router-view />
        </main>
      </v-main>

      <v-footer color="primary" app inset>
        <span class="black--text">
          &nbsp;&nbsp;&nbsp;&nbsp; &copy; {{ new Date().getFullYear() }}
        </span>
        <v-spacer />
        <span class="black--text"> Created by NT PLC Central Region </span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import watermark from "../watermark.js";
import { mdiReceiptTextOutline } from "@mdi/js";
import { mdiFileTableBox } from "@mdi/js";
import { mdiTextBoxSearch } from "@mdi/js";
import { mdiCogs } from '@mdi/js';
export default {
  name: "Main",
  components: {},
  props: {
    // source: String,
  },
  data() {
    return {
      drawer: null,
      appName: "BCRM",
      menuItems: [
        // { icon: 'mdi-home', title: 'Home', link: '/home' },
        {
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          link: "/dashboard",
          show: true,
        },
        {
          icon: "mdi-card-account-details",
          title: "ลูกค้า",
          show: this.$store.getters["user/canReadCustomer"],
          children: [
            {
              icon: "mdi-card-account-details",
              title: "ข้อมูลลูกค้า",
              link: "/customer",
              show: this.$store.getters["user/canReadCustomer"],
            },
            {
              icon: mdiTextBoxSearch,
              title: "ค้นหาข้อมูลลูกค้า",
              link: "/search-customer",
              show: this.$store.getters["user/canReadCustomer"],
            },
            {
              icon: mdiFileTableBox,
              title: "ข้อมูล CRM",
              link: "/customer-crm-data",
              show: this.$store.getters["user/canReadCustomer"],
            },
            {
              icon: mdiReceiptTextOutline,
              title: "ข้อมูลใบแจ้งหนี้พิเศษ",
              link: "/customer-special-bill-data",
              show: this.$store.getters["user/canReadCustomer"],
            },
            {
              icon: "mdi-dolly",
              title: "อุปกรณ์ที่ใช้งาน",
              link: "/customer-inventory",
              show: this.$store.getters["user/canReadCustomer"],
            },
            {
              icon: "mdi-handshake",
              title: "รายการเข้าพบ",
              link: "/customer-meeting",
              show: this.$store.getters["user/canReadCustomer"],
            },
            {
              icon: mdiCogs,
              title: "งาน SI",
              link: "/customer-si",
              show: this.$store.getters["user/canReadCustomer"],
            },
          ],
        },

        // {
        //   icon: "mdi-toolbox",
        //   title: "บริการที่ใช้งาน",
        //   link: "/customer-use-service",
        //   show: this.$store.getters["user/canReadCustomer"],
        // },
        {
          icon: "mdi-calendar",
          title: "ปฏิทิน",
          link: "/calendar",
          show: this.$store.getters["user/canReadCustomer"],
        },
        {
          icon: "mdi-file-document",
          title: "รายงาน",
          link: "/report",
          show: this.$store.getters["user/canReadCustomer"],
        },
        {
          icon: "mdi-database",
          title: "ฐานข้อมูล",
          show: this.$store.getters["user/canReadDatabase"],
          children: [
            {
              icon: mdiFileTableBox,
              title: "ข้อมูล CRM",
              link: "/crm-data",
              show: this.$store.getters["user/canReadCustomer"],
            },
            {
              icon: mdiReceiptTextOutline,
              title: "ข้อมูลใบแจ้งหนี้พิเศษ",
              link: "/special-bill-data",
              show: this.$store.getters["user/canReadCustomer"],
            },
          ],
        },
        {
          icon: "mdi-account-group",
          title: "ผู้ใช้งาน",
          link: "/user",
          show: this.$store.getters["user/canReadUser"],
        },
        { icon: "mdi-email", title: "ติดต่อเรา", link: "/contact", show: true },
      ],
      avatarMenuItems: [
        { icon: "mdi-account", title: "Profile", link: "/profile" },
      ],
      avatarPrefix:
        process.env.VUE_APP_STATIC_BASE_URL + "images/profile/avatars/",
    };
  },
  mounted: function () {
    watermark.set(this.$store.getters["user/username"]);
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/isLoggedIn"];
    },
    userFullName() {
      return this.$store.getters["user/userFullName"];
    },
    userAvatar() {
      return this.avatarPrefix + this.$store.getters["user/userAvatar"];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout").then(() => {
        this.$router.go("/login");
      });
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
.no-uppercase {
  text-transform: unset !important;
}
</style>
